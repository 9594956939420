
export default {
  name: "Range",
  props: {
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 100,
    },
    step: {
      type: [String, Number],
      default: 1,
    }
  },
  data() {
    return {
      value: 1,
    }
  },
  watch: {
    value(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    setValue(amount) {
      this.value = +((this.max / 100) * amount);
    }
  }
}
