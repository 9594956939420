
export default {
  name: "Card",
  props: {
    title: String,
    value: String,
    value2: String,
    mobileTitle: Object,
    size: {
      type: String,
      default: 'half',
    }
  }
}
