
export default {
    name: "CoreSelectSimple",
    props: {
        title: String,
        addNewBtn: String,
        options: Array,
        defaultOpt: [Number, String],
    },
    data() {
        return {
            selected: null,
            optionsBox: {
                flag: false,
            }
        }
    },
    watch: {
        selected(newVal) {
            this.$emit('input', newVal.id);
        }
    },
    methods: {
        toggleOptList() {
            this.optionsBox.flag = !this.optionsBox.flag;
        },
        selectOption(option) {
            this.selected = option;
            this.toggleOptList();
        },
    },
    fetch() {
        if (this.defaultOpt || this.defaultOpt === 0) {
            this.selected = this.options[this.defaultOpt];
        }
    }
}
