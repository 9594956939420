
export default {
  name: "Radio",
  props: {
    title: String,
    name: String,
    options: Array,
    defaultOption: Number,
    fontEn: Boolean,
  },
  data() {
    return {
      selected: false,
    }
  },
  watch: {
    selected(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    selectOpt(index) {
      if (!this.options[index].disabled)
        this.selected = this.options[index];
    }
  },
  beforeMount() {
    if (this.defaultOption || this.defaultOption === 0) {
      this.selectOpt(this.defaultOption);
    }
  }
}
