
export default {
  name: "CoreInputTextField",
  props: {
    label: String,
    lg: Boolean,
    outline: Boolean,
    disabled: Boolean,
    dataRules: [String, Object],
    value: String,
    ltr: Boolean
  },
  data() {
    return {
      inputVal: null,
      hasError: true,
    }
  },
  watch: {
    hasError(newVal) {
      if (newVal) {
        this.$emit('hasError', true);
      } else {
        this.$emit('hasError', false);
      }
    }
  }
}
