
export default {
  name: "CoreInputCheckbox",
  props: {
    label: String,
    required: Boolean,
  },
  data(){
    return {
      modelValue: false,
    }
  }
}
