

export default {
  name: "CoreInputPasswordField",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: String,
    checkValidation: {
      type: Boolean,
      default: false,
    },
    dataRules: [String, Object],
    required: Boolean,
  },
  data() {
    return {
      modelValue: null,
      errors: [],
      hasError: true,

      passwordStrength: null,
      passwordHiddenFlag: false,
      passwordStrengthLabel: null,
    }
  },
  watch: {
    passwordStrength(newVal) {
      if (newVal === 'very-weak') {
        this.passwordStrengthLabel = 'ضعیف';
      } else if (newVal === 'weak') {
        this.passwordStrengthLabel = 'متوسط';
      } else if (newVal === 'medium') {
        this.passwordStrengthLabel = 'نسبتا قوی';
      } else if (newVal === 'strong') {
        this.passwordStrengthLabel = 'قوی';
      } else {
        this.passwordStrengthLabel = '';
      }
    },
    hasError(newVal) {
      if (newVal) {
        this.$emit("hasError", true);
      } else {
        this.$emit("hasError", false);
      }
    }
  },
  methods: {
    toggleHidden() {
      this.passwordHiddenFlag = !this.passwordHiddenFlag;
    },
    onChange() {
      this.$emit('input', this.modelValue);
      this.errors = [];
      if (this.checkValidation) {
        this.passwordStrength = this.checkStrength(this.modelValue);
        if (this.passwordStrength === 'very-weak') {
          this.errors.push('بسیار ضعیف');
        } else if (this.passwordStrength === 'weak') {
          this.errors.push('ضعیف');
        }
      }
      this.$emit("hasError", (this.errors.length > 0) ? this.errors[0] : false);
    },
    checkStrength(password) {
      let strength = 0;

      //If password contains both lower and uppercase characters
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
      }
      //If it has numbers and characters
      if (password.match(/([0-9])/)) {
        strength += 1;
      }
      //If it has one special character
      if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
      }
      //If password is greater than 7
      if (password.length > 7) {
        strength += 1;
      }

      if (password.length > 10) {
        strength += 1;
      }

      // If value is less than 2
      if (strength === 1) {
        return 'very-weak';
      } else if (strength === 2) {
        return 'weak';
      } else if (strength === 3 || strength === 4) {
        return 'medium';
      } else if (strength === 5) {
        return 'strong';
      }
    }
  }
}

