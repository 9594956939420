
export default {
  name: "CoreSelectCard",
  props: {
    title: String,
    addNewBtn: String,
    options: Array,
  },
  data() {
    return {
      selected: null,
      optionsBox: {
        flag: false,
      }
    }
  },
  computed: {
    optionsList() {
      let options = this.options;
      let newOptions = [];
      options.forEach(option => {
        let body = [];
        option.body.forEach(span => {
          let labelAndValue = span.split(':');
          body.push({
            label: labelAndValue[0],
            value: labelAndValue[1],
          });
        });

        newOptions.push({
          id: option.id ? option.id : '',
          icon: option.icon,
          title: option.title,
          body,
        });

      });

      return newOptions;
    }
  },
  watch: {
    selected(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    toggleOptList() {
      this.optionsBox.flag = !this.optionsBox.flag;
    },
    selectOption(option) {
      this.selected = option;
      this.toggleOptList();
    },
    goToAddNew(){
      this.$router.push(this.addNewBtn);
      this.$store.dispatch("dashboardPopupForm/closePopup");
    }
  }
}
