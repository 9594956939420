
export default {
  name: "CoreInputNumberField",
  props: {
    amount: [Number, String],
    label: String,
    lg: Boolean,
    disabled: Boolean,
    outline: Boolean,
    lot: "1",
  },
  data() {
    return {
      inputVal: this.amount ? this.amount : "",
      hasError: null,
      errors: null,
      loadingFlagTimer: null,
    };
  },
  computed: {
    exactValue() {
      return parseFloat(this.inputVal.toString().replaceAll(",", ""));
    },
  },
  watch: {
    amount(newVal) {
      if (newVal || newVal === 0) {
        this.inputVal = this.addComma(newVal);
      } else {
        this.inputVal = "";
      }
    },
    exactValue(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    addComma(num) {
      let parts = num.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return (
        numberPart.replace(thousands, ",") +
        (decimalPart ? "." + decimalPart : "")
      );
    },
  },
};
