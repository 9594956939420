
export default {
  name: "CustomInput",
  props: {
    type: {
      type: String,
      default: 'simple',
    },
    placeHolder: {
      type: Object,
      default() {
        return {
          icon: null,
          label: null,
          hint: null,
        }
      }
    },
    disabled: Boolean,
  },
  data() {
    return {
      inputVal: null,
    }
  }
}
