
// accept video from user

export default {
  name: "CoreInputFileField",
  props: {
    label: String,
    outline: Boolean,
    acceptType: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      fileName: null,
      fileUrl: null,
    };
  },
  watch: {
    hasError(newVal) {
      if (newVal) {
        this.$emit("hasError", true);
      } else {
        this.$emit("hasError", false);
      }
    },
  },
  methods: {
    onFileChange(file) {
      let files = file.target.files || file.dataTransfer.files;
      if (files.length > 0) {
        try {
          let reader = new FileReader();
          let file = files[0];
          if (this.acceptType === "image/*") {
            reader.readAsDataURL(file);
            reader.onload = (e) => {
              this.fileUrl = e.target.result;
              this.$emit("getFileUploaded", {
                url: this.fileUrl,
                size: file.size,
              });
            };
          } else {
            this.$emit("getFileUploaded", file);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    onFileClear() {
      this.fileUrl = null;
      this.$emit("clearFile");
    },
  },
};
