
import QrcodeVue from "qrcode.vue";

export default {
  name: "CoreInputInfoField",
  components: {
    QrcodeVue
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    hasCopy: Boolean,
    qrCode: String,
    fontEn: Boolean,
    blueOutline: Boolean,
    greenOutline: Boolean,
  },
  data(){
    return {
      copyFlag: false,
    }
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text);
      this.copyFlag = true;
      setTimeout(() => {
        this.copyFlag = false;
      }, 1000);
    },
  }
}
