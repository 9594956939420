
export default {
  name: "Simple",
  props: {
    name: String,
    label: String,
    icon: String,
  },
  data() {
    return {
      checked: false,
    }
  }
}
