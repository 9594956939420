
export default {
  name: "CoreInputCodeField",
  props: {
    fields: {type: Number, default: 6},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: true},
    goFocusOn: {type: Number, default: 0},
  },
  data() {
    return {
      values: [],
      iRefs: [],
      inputs: [],
      autoFocusIndex: 0,
    }
  },
  computed: {
    focusIndex() {
      return this.goFocusOn;
    }
  },
  methods: {
    initVals() {
      let vals;
      if (this.values && this.values.length) {
        vals = [];
        for (let i = 0; i < this.fields; i++) {
          vals.push(this.values[i] || "");
        }
        autoFocusIndex.value = this.values.length >= this.fields ? 0 : this.values.length;
      } else {
        vals = Array(this.fields).fill("");
      }
      this.iRefs = [];
      for (let i = 0; i < this.fields; i++) {
        this.iRefs.push(i + 1);
      }
      this.values = vals;
    },
    onFocus(e) {
      e.target.select(e);
    },
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id);
      e.target.value = e.target.value.replace(/[^\d]/gi, "");
      // this.handleKeys[index] = false;
      if (e.target.value === "" || !e.target.validity.valid) {
        return;
      }
      let next;
      const value = e.target.value;
      this.values = Object.assign([], this.values);
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= this.fields) {
          nextIndex = this.fields - 1;
        }
        next = this.iRefs[nextIndex];
        const split = value.split("");
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < this.fields) {
            this.values[cursor] = item;
          }
        });
      } else {
        next = this.iRefs[index + 1];
        this.values[index] = value;
      }
      if (next) {
        const element = this.inputs[next];
        element.focus();
        element.select();
      }
      this.triggerChange(this.values);
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.iRefs[prevIndex];
      const next = this.iRefs[nextIndex];
      switch (e.keyCode) {
        // 8 = backspace key
        case 8: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = "";
            this.values = vals;
            this.triggerChange(vals);
          } else if (prev) {
            vals[prevIndex] = "";
            this.inputs[prev].focus();
            this.values = vals;
            this.triggerChange(vals);
          }
          break;
        }
        // 37 = left arrow key
        case 37:
          e.preventDefault();
          if (prev) {
            this.inputs[prev].focus();
          }
          break;
        // 39 = right arrow key
        case 39:
          e.preventDefault();
          if (next) {
            this.inputs[next].focus();
          }
          break;
      }
    },
    triggerChange(values = this.values) {
      const val = values.join("");
      this.$emit("change", val);
      val.length >= this.fields ? this.$emit("complete", val) : null;
    }
  },
  mounted() {
    this.inputs[1].focus();
  },
  fetch() {
    this.initVals();
  }
}
/*
const props = defineProps({
  fields: {type: Number, default: 5},
  disabled: {type: Boolean, default: false},
  required: {type: Boolean, default: true},
  goFocusOn: {type: Number, default: 0},
});

const emit = defineEmits(["change", "complete"]);
// const emit = defineEmits(["change"]);

const values = ref([]);
const iRefs = ref([]);
const inputs = ref([]);
const fields = toRef(props, "fields");
const focusIndex = computed(() => props.goFocusOn);
const autoFocusIndex = ref(focusIndex.value);

const initVals = () => {
  let vals;
  if (values.value && values.value.length) {
    vals = [];
    for (let i = 0; i < fields.value; i++) {
      vals.push(values.value[i] || "");
    }
    autoFocusIndex.value =
      values.value.length >= fields.value ? 0 : values.value.length;
  } else {
    vals = Array(fields.value).fill("");
  }
  iRefs.value = [];
  for (let i = 0; i < fields.value; i++) {
    iRefs.value.push(i + 1);
  }
  values.value = vals;
};
const onFocus = (e) => {
  e.target.select(e);
};
const onValueChange = (e) => {
  const index = parseInt(e.target.dataset.id);
  e.target.value = e.target.value.replace(/[^\d]/gi, "");
  // this.handleKeys[index] = false;
  if (e.target.value === "" || !e.target.validity.valid) {
    return;
  }
  let next;
  const value = e.target.value;
  values.value = Object.assign([], values.value);
  if (value.length > 1) {
    let nextIndex = value.length + index - 1;
    if (nextIndex >= fields.value) {
      nextIndex = fields.value - 1;
    }
    next = iRefs.value[nextIndex];
    const split = value.split("");
    split.forEach((item, i) => {
      const cursor = index + i;
      if (cursor < fields.value) {
        values.value[cursor] = item;
      }
    });
  } else {
    next = iRefs.value[index + 1];
    values.value[index] = value;
  }
  if (next) {
    const element = inputs.value[next];
    element.focus();
    element.select();
  }
  triggerChange(values.value);
};
const onKeyDown = (e) => {
  const index = parseInt(e.target.dataset.id);
  const prevIndex = index - 1;
  const nextIndex = index + 1;
  const prev = iRefs.value[prevIndex];
  const next = iRefs.value[nextIndex];
  switch (e.keyCode) {
    // 8 = backspace key
    case 8: {
      e.preventDefault();
      const vals = [...values.value];
      if (values.value[index]) {
        vals[index] = "";
        values.value = vals;
        triggerChange(vals);
      } else if (prev) {
        vals[prevIndex] = "";
        inputs.value[prev].focus();
        values.value = vals;
        triggerChange(vals);
      }
      break;
    }
    // 37 = left arrow key
    case 37:
      e.preventDefault();
      if (prev) {
        inputs.value[prev].focus();
      }
      break;
    // 39 = right arrow key
    case 39:
      e.preventDefault();
      if (next) {
        inputs.value[next].focus();
      }
      break;
  }
};
const triggerChange = (values = values.value) => {
  const val = values.join("");
  emit("change", val);
  val.length >= fields.value ? emit("complete", val) : null;
};
const fetch = useFetch(() => {
  initVals();
});
onMounted(() => {
  inputs.value[1].focus();
});*/
