
export default {
  name: "NewInfoField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    fontEn: Boolean,
    redOutline: Boolean,
    greenOutline: Boolean,
  },
  data() {
    return {
      copyFlag: false,
    }
  },
  computed: {
    classes() {
      return {'red': this.redOutline, 'green': this.greenOutline};
    }
  },
}
